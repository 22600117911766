import { render, staticRenderFns } from "./orderTalk.vue?vue&type=template&id=36eb07c2&scoped=true"
import script from "./orderTalk.vue?vue&type=script&lang=js"
export * from "./orderTalk.vue?vue&type=script&lang=js"
import style0 from "./orderTalk.vue?vue&type=style&index=0&id=36eb07c2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36eb07c2",
  null
  
)

export default component.exports