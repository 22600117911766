<template>
  <div>
    <div class="personMsg">
      <div class="personMsgFl">
        <div class="flOne">
          <span class="name">{{ resumeInfo.user_info.name_zh }}</span>
          <span class="sex"
          ><i class="el-icon-male" v-if="resumeInfo.user_info.sex == 1"></i>
            <i v-if="resumeInfo.user_info.sex == 2" class="el-icon-female"></i
            ></span>
          <span class="upTime"
          >更新时间：{{
              resumeInfo.user_info.updated_time | secondFormat("LL")
            }}</span
          >
        </div>
        <div class="flTwo">
          现居住：{{ resumeInfo.resume_info.city }} |
          {{ resumeInfo.user_info.level | levelRequireFormat(LOCALE) }} |
          {{ resumeInfo.user_info.age }}岁 （{{
            resumeInfo.user_info.birthday | secondFormat("LL")
          }}）
        </div>
        <div class="flThree">
          <span
          ><img :src="telIcon" alt="" srcset="" />{{
              resumeInfo.user_info.mobile | textFormat
            }}
          </span>
          <span class="wx"
          ><img :src="wxIcon" alt="" srcset="" />{{
              resumeInfo.user_info.wechat | textFormat
            }}
          </span>
          <span
          ><img :src="emailIcon" alt="" srcset="" />{{
              resumeInfo.user_info.email | textFormat
            }}
          </span>
        </div>
      </div>
      <div class="personMsgFr">
        <el-avatar
            shape="circle"
            :size="100"
            :fit="'contain'"
            :src="
            resumeInfo.user_info.avatar
              ? resumeInfo.user_info.avatar
              : 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/grounp.png'
          "
        ></el-avatar>
      </div>
    </div>
    <div class="advantage">
      <div class="centerTitle">个人优势</div>
      <p class="advantageContent">
        {{ resumeInfo.resume_info.advantage }}
      </p>
    </div>
    <div class="treatment">
      <div class="centerTitle">期望职位</div>
      <div
          class="treatmentItem"
          v-for="(item, index) in resumeInfo.expected_position_info"
          :key="index"
      >
        <div class="title">
          <img :src="zwIcon" alt="" srcset="" />
          <span
          >{{ item.position_type | positionTypeListFormat(LOCALE) }}
          </span>
        </div>
        <div class="priceIcon">
          <img :src="priceIcon" alt="" srcset="" />
          <span
          >薪资要求： {{ item.salary_lowest }}-{{ item.salary_highest }}K /月
          </span>
        </div>
        <div class="address">
          <img :src="addressIcon" alt="" srcset="" />
          <span>工作城市 ：{{ item.city }} </span>
        </div>
        <div class="type">
          <img :src="qzIcon" alt="" srcset="" />
          <span
          >求职类型：{{ item.position_kind == 1 ? "全职" : "兼职" }}
          </span>
        </div>
      </div>
    </div>
    <div class="wordOrinternship">
      <div class="centerTitle">工作/实习经历</div>
      <div
          class="wordDetail"
          v-for="(item, index) in resumeInfo.work_experience_info"
          :key="index"
      >
        <p class="companyName">
          {{ item.company_name | textFormat }}
          <span class="time" v-if="item.start_time"
          >{{ item.start_time | secondFormat("YM") }} ~
            {{ item.end_time | secondFormat("YM") }}</span
          >
        </p>
        <p class="title">
          {{ item.position_name |textFormat }} |
          {{ item.department_name  |textFormat}}
        </p>
        <p class="describe" style="word-wrap: break-word">
          {{ item.experience }}
        </p>
      </div>
    </div>

    <div class="wordOrinternship">
      <div class="centerTitle">教育经历</div>
      <div
          class="wordDetail"
          v-for="(item, index) in resumeInfo.educational_experience_info"
          :key="index"
      >
        <p class="companyName">
          {{ item.school_name | textFormat }}
          <span class="time" v-if="item.start_time"
          >{{ item.start_time | secondFormat("YM") }} ~
            {{ item.end_time | secondFormat("YM") }}</span
          >
        </p>
        <p class="title">
          {{ item.class_type == 1 ? "全日制" : "非全日制"
          }}{{ item.level | levelRequireFormat(LOCALE) }} |
          {{ item.major | textFormat }}
        </p>
        <p class="describe">
          {{ item.experience }}
        </p>
      </div>
    </div>
    <div class="certificate">
      <div class="centerTitle">资格证书</div>
      <div
          class="certificateList"
          v-for="(item, index) in resumeInfo.job_certificate_info"
          :key="index"
      >
        <el-button class="btns" size="small" plain>{{ item.name }}</el-button>
        <p v-if="item.attachment_list.length">
          <a :href="item.attachment_list[0].url"
          ><img
              :src="
                item.attachment_list[0].file_type | enclosureImgbyTypeFormat
              "
              alt=""
              srcset=""
          />
            {{ item.attachment_list[0].file_name }}</a
          >
        </p>
      </div>
    </div>
    <div class="baseFile">
      <div class="centerTitle">附件</div>
      <div class="baseFileList" v-if="resumeInfo.resume_info.attachment_list.length">
        <p>
          <img
              :src="
              resumeInfo.resume_info.attachment_list[0].file_type
                | enclosureImgbyTypeFormat
            "
              alt=""
          />
          <span>{{ resumeInfo.resume_info.attachment_list[0].file_name }}</span>

          <el-button class="btns" size="mini" plain @click="dialogVisible=true">预览</el-button>
        </p>
      </div>
    </div>

    <el-dialog
        title="查看简历附件"
        :visible.sync="dialogVisible"
        width="700px"
        append-to-body
    >
      <filePreview  style="min-height:500px" v-if="dialogVisible" :fileSrc=" resumeInfo.resume_info.attachment_list[0].url"></filePreview>
    </el-dialog>
  </div>
</template>

<script>
import filePreview from "~/baseComponents/filePreview";
export default {
  props: {
    resumeInfo: Object,
  },
  components:{filePreview},
  created() {
    //   console.log() ;
    let num = this.$moment().unix() - this.resumeInfo.user_info.birthday;
    this.resumeInfo.user_info.age = Math.floor(num / 31536000);

  },
  data() {
    return {
      dialogVisible:false,
      telIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/tel.png",
      wxIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/wx.png",
      emailIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/email.png",
      zwIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/zw.png",
      priceIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/peice.png",
      addressIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/address.png",
      qzIcon:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/schoolRecruitment/qz.png",
    };
  },
};
</script>

<style scoped lang="less">
.el-icon-female {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #f94fa2;
  color: #fff;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}
.el-icon-male {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #66b1ff;
  color: #fff;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}
.centerTitle {
  color: #333333;
  font-size: 18px;
  font-weight: 900;
  border-left: 2px solid #298df8;
  padding-left: 15px;
}
.personMsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
  .personMsgFl {
    .flOne {
      display: flex;
      align-items: center;
      .name {
        font-size: 24px;
        color: #333;
        font-weight: 900;
        padding-right: 10px;
      }
      .upTime {
        color: #999;
        font-size: 14px;
        padding-left: 25px;
      }
    }
    .flTwo {
      color: #666;
      font-size: 14px;
      padding: 10px 0;
    }
    .flThree {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        &.wx {
          margin: 0 25px;
        }
        img {
          width: 18px;
          margin-right: 5px;
        }
      }
    }
  }
  .personMsgFr {
    padding-right: 150px;
  }
}
.advantage {
  padding: 24px 0;
  border-bottom: 1px solid #e8e8e8;
  .advantageContent {
    font-size: 14px;
    color: #666;
    padding-top: 24px;
    padding-left: 16px;
  }
}
.treatment {
  padding: 24px 0;
  border-bottom: 1px solid #e8e8e8;
  .treatmentItem {
    display: flex;
    padding-left: 16px;
    align-items: center;
    margin-top: 20px;
    & > div {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        margin-right: 5px;
      }
      span {
        color: #333;
        font-size: 14px;
        margin-right: 20px;
      }
    }
  }
}
.wordOrinternship {
  padding: 24px 0;
  border-bottom: 1px solid #e8e8e8;
  .wordDetail {
    padding-left: 16px;
    margin-top: 16px;
    .companyName {
      font-size: 16px;
      font-weight: 900;
      color: #1f292e;
      span {
        color: #666666;
        font-size: 16px;
        padding-left: 25px;
      }
    }
    .title {
      font-size: 14px;
      color: #999;
      padding: 16px 0;
    }
    .describe {
      font-size: 14px;
      color: #666;
    }
  }
}
.certificate {
  padding: 24px 0;
  border-bottom: 1px solid #e8e8e8;
  .certificateList {
    margin: 18px 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    p {
      padding-left: 15px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
.baseFile {
  padding: 24px 0;
  border-bottom: 1px solid #e8e8e8;
}
.baseFileList {
  p {

    display: flex;
    align-items: center;
    margin-top: 25px;
    img {
      width: 25px;
      margin-right: 5px;
    }
    .btns {
      margin-left: 20px;
    }
  }
}
</style>